<template>
  <div class="wraper">
    <button
      @click="$emit('hideModal')"
      class="close-btn btn btn-close"
    ></button>
    <div class="container p-0">
      <div class="row w-100 align-items-center justify-content-center">
        <PricingCard v-for="(price, i) in pricing" :key="i" :price="price" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import PricingCard from '../../pricing/card/card'
export default {
  name: 'modalFeedOff',
  components: { PricingCard },
  data: () => ({
    pricing: null,
    form: {},
    error: {},
  }),
  methods: {
    ...mapActions('Pricing', ['getPricing', 'sendInvest']),
    initData() {
      this.getPricing().then(({ data }) => {
        this.pricing = data
        this.pricing.forEach(e => {
          this.form[e._id] = ''
        })
      })
    },
    onSubmit(id) {
      const selected = this.pricing.find(e => e._id == id)
      const form = {
        pricePackage: id,
        invest: this.form[id],
      }
      if (
        (form.invest < selected.investMin) |
        (form.invest > selected.investMax)
      ) {
        this.error = id
        return
      }
      this.error = ''
      this.sendInvest(form)
    },
  },
  mounted() {
    this.initData()
  },
}
</script>

<style src="./modal.scss" lang="scss"></style>
<style src="../../pricing/styles/desktopStyle.scss" lang="scss"></style>
